/* Hamburger Icon */
.hamburger-menu {
  position: fixed;
  top: 10px;
  left: 14px;
  font-size: 50px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  z-index: 1001;
  opacity: 0.7;
}

.hamburger-menu:hover {
  opacity: 1;
}

/* Menu Overlay */
.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7); /* Adjust opacity for desired darkness */
  backdrop-filter: blur(10px); /* Blur effect */
  z-index: 1000;
  overflow-y: hidden; /* Prevent scrolling when overlay is open */
}


/* Centered Menu Content */
.menu-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  padding: 20px;
}

.menu-item {
  color: white;
  font-size: 6vw;
  text-decoration: none;
  font-family: "PP Monument Extended", sans-serif;
  font-weight: bold;
  transition: color 0.3s;
}

.menu-item:hover {
  color: #00FF8D;
}

