/* Global Styles */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

@font-face {
  font-family: "PP Monument Extended";
  src: url('./fonts/PPMonumentExtended-Black.otf') format('opentype');
  font-weight: 900; /* Typically, 900 is used for 'Black' weights */
  font-style: normal;
}

@font-face {
  font-family: "PP Monument Extended";
  src: url('./fonts/PPMonumentExtended-Bold.otf') format('opentype');
  font-weight: bold; /* Use 'bold' for this weight */
  font-style: normal;
}

@font-face {
  font-family: "PP Monument Extended";
  src: url('./fonts/PPMonumentExtended-Regular.otf') format('opentype');
  font-weight: normal; /* 'normal' weight for regular */
  font-style: normal;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    background-color: black;
  }
  100% {
    opacity: 1;
    background-color: transparent;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html {
  height: 100%; /* Ensure html element takes full height */
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  background-color: black;
  background-image: url('./images/largelongbackground.png');
  background-size: auto;
  background-position: top center;
  background-repeat: no-repeat;
}

body::after {
  content: '';
  display: block;
  height: 100vh;
  width: 100vw;
  background-image: url('./images/largelongbackground.png');
  background-size: auto;
  background-position: top center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}


/* Hero Section */
.hero {
  margin-left: 40px;
  flex: 0 0 75%; /* Take up 75% of the viewport height */
  display: flex;
  justify-content: flex-start; /* Align items towards the left */
  align-items: flex-start; /* Align items to the top vertically */
  text-align: left; /* Start with left-aligned text */
  padding-top: 20px; /* Add padding to give some space at the top */
  position: relative; /* Position the hero section */
  z-index: 10; /* Ensure hero content is above the bottom image */
}

.hero-content {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align content to the left for larger screens */
  justify-content: flex-start; /* Keep content at the top */
  max-width: 100%; /* Ensure image is responsive */
  padding: 20px; /* Adjust padding for responsiveness */
  z-index: 10; /* Keep content above the bottom image */
}
.footer {
  background-color: #1A1A1D; /* Dark background */
  color: #FFFFFF; /* White text */
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  font-family: 'PPMonument Extended', sans-serif;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  max-width: 1200px;
  flex-wrap: wrap;
}

.footer-section {
  flex: 1;
  margin: 0 10px;
}

.logo-social {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.logo {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "PP Monument Extended", sans-serif;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons img {
  width: 24px;
  height: 24px;
}

.footer-section h4 {
  font-size: 1rem;
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: "PP Monument Extended", sans-serif;

}

.footer-section ul {
  list-style: none;
  padding: 0;
  font-family: 'Inter', sans-serif;
}

.footer-section li {
  margin-bottom: 5px;
  font-size: .8rem;
  font-family: 'Inter', sans-serif;
}

.footer-section a {
  color: #FFFFFF;
  font-size: .8rem;
  text-decoration: none;
  font-family: 'Inter', sans-serif;
}

.footer-section a:hover {
  text-decoration: underline;
  font-family: 'Inter', sans-serif;
}

/* Styling for the header and paragraph elements */

h1 {
  font-size: 4rem; /* Adjust font size for larger screens */
  margin-top: 10px; /* 20px above the h1 */
  font-family: "PP Monument Extended", sans-serif;
  font-size: 120px;
  font-weight: 800;
  margin-bottom: 10px;
  color: white;
}

h2 {
  font-size: 3rem; /* Adjust font size for larger screens */
  margin-bottom: 10px; /* 20px below the h2 */
  font-family: "PP Monument Extended", sans-serif;
  font-size: 100px;
  font-weight: 800; /* Bold */
  color: white;
}

h3 {
  font-size: 2rem; /* Adjust font size for larger screens */
  margin-top: 10px; /* 20px above the h1 */
  font-family: "PP Monument Extended", sans-serif;
  font-size: 70px;
  font-weight: bold;
  margin-bottom: 10px;
  color: white;
}
h4 {
  font-size: 2rem; /* Adjust font size for larger screens */
  margin-top: 10px; /* 20px above the h1 */
  font-family: "PP Monument Extended", sans-serif;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #00FF8D;
}

p {
    font-size: 16px;
    color: white;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
  }

a {
  color: #00FF8D;
  text-decoration: none; /* Optional: remove underline */
}

a:hover {
  text-decoration: underline; /* Optional: add underline on hover */
}

.text-center {
  text-align: center;
  align-items: center;
}
/* CSS for the Scroll-to-Top button */
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background-color: transparent; /* Remove background color */
  color: rgba(255, 255, 255, 0.5); /* Set icon color to semi-transparent white */
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.scroll-to-top:hover {
  color: rgba(255, 255, 255, 1); /* Fully */
}

.chevron-icon {
  width: 3vh;
  height: auto; /* Adjust size if needed */
  color: white; /* Change color as desired */
}



/* Grouped game images (stacked vertically) */

.game-images {
  display: flex;
  flex-direction: column; /* Stack the images vertically */
  align-items: left; /* Center the images */
  width: 50%; /* Set the responsive width to match the original image */
  height: auto;
}

.game-image {
  width: 90%; /* Responsive width */
  height: auto;
  opacity: 0; /* Hidden by default */
  animation: fadeInVertical .8s ease-out forwards; /* Fade in animation */
}

.game1 {
  animation-delay: 0.4s; /* Game1 fades in first */
}
.game2 {
  animation-delay: 0.6s; /* Game2 fades in after Game1 */
}
.game3 {
  animation-delay: 0.8s; /* Game3 fades in after Game2 */
  margin-bottom: -5%;
}
.game4 {
  animation-delay: 1s; /* Game4 fades in after Game3 */
  margin-bottom: -3%;
}

/* Fade in with upward motion */
@keyframes fadeInVertical {
  0% {
    opacity: 0;
    transform: translateY(20px); /* Slight upward movement */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Settle in place */
  }
}

.hero-button {
  display: flex;
  align-items: center;
  background: none; /* Remove background */
  border: none; /* Remove borders */
  cursor: pointer; /* Pointer cursor on hover */
  opacity: 0; /* Initially hidden */
  animation: zoomIn .5s ease-out forwards; /* Change to zoom-in animation */
  animation-delay: 1.5s; /* Starts after all images have loaded */
}

/* Zoom-in animation */

@keyframes zoomIn {
  0% {
    opacity: 0; /* Start fully transparent */
    transform: scale(0.5); /* Start at half size */
  }
  100% {
    opacity: 1; /* Fully visible */
    transform: scale(1); /* End at full size */
  }
}

/* Text below button */
.telegram-text {
  color: #FFF;
  text-align: center;
  font-family: "PP Monument Extended", sans-serif;
  font-size: 1rem;
  font-weight: 800;
  margin-top: 20px;
  opacity: 0; /* Initially hidden */
  animation: zoomIn .5s ease-out forwards; /* Change to zoom-in animation */
  animation-delay: 1.5s; /* Starts after all images have loaded */
}

/* Bottom Image Container */
.bottom-image-container {
  position: relative; /* Change to relative for proper positioning */
  right: 0; /* Align to the bottom right */
  margin-right: 70px;
  width: auto; /* Full width for responsiveness */
  height: auto; /* Allow it to take up to 40% of the viewport height on larger screens */
  display: flex;
  justify-content: flex-end; /* Initially align the image to the right */
  align-items: flex-end; /* Align the image to the bottom */
  z-index: 1; /* Set the image behind the hero content */
  animation: shoot-up 1.5s ease-out forwards; /* Apply shooting up animation */
  animation-delay: 2s; /* Starts after all images have loaded */
  opacity: 0; /* Set initial opacity to 0 */
}

/* Bottom Image */
.bottom-image {
  width: auto;
  height: auto;
  max-height: 30%; /* Keep aspect ratio */
  max-width: 30%;
  object-fit: contain; /* Keep aspect ratio intact */
  animation: fadeInUp .8s ease-out forwards; /* Add animation for upward movement */
}

/* Shoot-up animation */
@keyframes shoot-up {
  0% {
    opacity: 0;
    transform: translateY(50vh); /* Start from below the viewport */
  }
  100% {
    opacity: 1;
    transform: translateY(-50vh); /* Settle into the final position */
  }
}


/* Styling for everything in the section below the hero */
.new-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    min-height: 60vh; /* Reduce height to prevent extra space */
}

.responsive-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row; /* Place the image next to the text */
  margin-top: 20px;
}

.iphone-image-container-left {
  flex: 1;
  display: flex;
  justify-content: left;
}

.responsive-image{
  max-width: 100%;
  height: auto;
}

.text-container-right {
  flex: 1;
  text-align: right; /* Right-align text */
  margin-right: 10%;
  margin-left: 10%;
}

.iphone-image-container-right {
  flex: 1;
  display: flex;
  justify-content: right;
  margin-right: 0px;
}

.text-container-left {
  flex: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
}

/* Center and style the parallax banner image */
.parallax-container {
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.parallax-banner-image {
  width: 100vw;  /* Set to viewport width */
  height: auto;
  max-width: none;}

/* Responsive behavior for common device sizes */
@media (min-width: 1601px) {
  body {
  margin: 0; /* Remove default margin */
  padding: 0;
  box-sizing: border-box;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  background-color: black;
  background-image: url('./images/monitorbackground.png'); /* Your background image */
  background-size: auto; /* Keep original size of the image */
  background-position: top center; /* Align the background image to the top center */
  background-repeat: no-repeat; /* Prevent the background from repeating */
}

/* Ensure the body height matches the image height */
body::after {
  content: '';
  display: block;
  height: 100vh; /* Set the height to match the viewport height */
  width: 100vw; /* Allow width to remain unchanged */
  background-image: url('./images/monitorbackground.png'); /* Duplicate background */
  background-size: auto; /* Keep the original size */
  background-position: top center; /* Keep it aligned with the original */
  background-repeat: no-repeat; /* Prevent repeating */
  position: absolute; /* Use absolute positioning to keep it behind other content */
  top: 0;
  left: 0;
  z-index: -1; /* Ensure it's behind other content */
}

}
@media (max-width: 1440px) { /* Desktop (large) */
h3 {
  margin-top: 10px; /* 20px above the h1 */
  font-family: "PP Monument Extended", sans-serif;
font-size: 2rem; /* Adjust font size for smaller screens */
  font-weight: Bold;
  margin-bottom: 10px;
}
h4 {
  margin-top: 10px; /* 20px above the h1 */
  font-family: "PP Monument Extended", sans-serif;
font-size: 1.5rem; /* Adjust font size for smaller screens */
  font-weight: bold;
  margin-bottom: 10px;
}
p {
    font-size: 1rem; /* Adjust font size for tablets */
  }
  .game-images{
    max-width: 80%; /* Adjust width for tablet */
  }
}

/* Responsive behavior for common device sizes */
@media (max-width: 1024px) { /* Tablet */
body {
   margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100vw; /* Ensure viewport width */
  height: 100vh; /* Ensure viewport height */
  overflow-x: hidden; /* Prevent horizontal scroll */
  background-color: black;
  background-image: url('./images/ipadbackground.png');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
 body::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  background-image: url('./images/ipadbackground.png');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  z-index: -1; /* Keep it behind other content */
}  
  .hero {
    justify-content: center; /* Center content on smaller screens */
    text-align: center; /* Center text on smaller screens */
    margin-left: 0px;
  }
  
  .hero-content {
    align-items: center; /* Center content on smaller screens */
  }

h1 {
  margin-top: 10px; /* 20px above the h1 */
  font-family: "PP Monument Extended", sans-serif;
font-size: 5rem; /* Adjust font size for smaller screens */
  font-weight: 800;
  margin-bottom: 10px;
}
h2 {
  margin-bottom: 10px; /* 20px below the h2 */
  font-family: "PP Monument Extended", sans-serif;
font-size: 5rem; /* Adjust font size for smaller screens */
  font-weight: 800;
}
h3 {
  margin-top: 10px; /* 20px above the h1 */
  font-family: "PP Monument Extended", sans-serif;
font-size: 4rem; /* Adjust font size for smaller screens */
  font-weight: Bold;
  margin-bottom: 10px;
}
h4 {
  margin-top: 10px; /* 20px above the h1 */
  font-family: "PP Monument Extended", sans-serif;
font-size: 2rem; /* Adjust font size for smaller screens */
  font-weight: bold;
  margin-bottom: 10px;
}
p {
    font-size: 1rem; /* Adjust font size for tablets */
  }

  .hero-button {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  .game-images {
    max-width: 70%; /* Adjust width for tablet */
  }
  .bottom-image-container {
    max-height: 40%; /* Adjust height to half of the original */
    position: relative; /* Ensure proper positioning for child elements */
    display: flex; /* Keep display flex for centering */
    justify-content: center; /* Center the bottom image horizontally */
    align-items: flex-end; /* Align the image to the bottom */
    margin: 0 auto; /* Center the container itself */
  }
  .bottom-image {
    min-width: 80%; /* Ensure the image covers the container */
    height: auto; /* Maintain aspect ratio */
    object-position: top; /* Aligns the image to show the top part */
  }

  /* Shoot-up animation */
@keyframes shoot-up {
  0% {
    opacity: 0;
    transform: translateY(50vh); /* Start from below the viewport */
  }
  100% {
    opacity: 1;
    transform: translateY(0vh); /* Settle into the final position */
  }
}
.new-section {
    display: flex;
    flex-direction: column;
    padding: 10px;
    height: 100vh; /* Adjust to keep within viewport height */
}
  .responsive-container {
    flex-direction: column; /* Stack the image and text vertically on smaller screens */
    text-align: center;
    margin-top: 40px;
  }

  .text-container-right {
    order: 1;
    margin-bottom: 20px;
  }

  .iphone-image-container-left {
    order: 2;
    margin-right: 0;
    margin-top: 20px;
    justify-content: left;
  }
  .text-container-left {
    order: 1;
    margin-bottom: 20px;
  }

  .iphone-image-container-right {
    order: 2;
    margin-right: 0;
    margin-top: 20px;
    justify-content: right;
  }
}

/* Responsive behavior for common device sizes */
@media (max-width: 820px) {
  .hero {
    justify-content: center; /* Center content on smaller screens */
    text-align: center; /* Center text on smaller screens */
    margin-left: 0px;
  }
  
  .hero-content {
    align-items: center; /* Center content on smaller screens */
  }
body {
   margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100vw; /* Ensure viewport width */
  height: 100vh; /* Ensure viewport height */
  overflow-x: hidden; /* Prevent horizontal scroll */
  background-color: black;
  background-image: url('./images/ipadbackground.png');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
 body::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  background-image: url('./images/ipadbackground.png');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  z-index: -1; /* Keep it behind other content */
}

h1 {
  margin-top: 5px; /* 20px above the h1 */
  font-family: "PP Monument Extended", sans-serif;
font-size: 4rem; /* Adjust font size for smaller screens */
  font-weight: 800;
  margin-bottom: 10px;
}
h2 {
  margin-bottom: 5px; /* 20px below the h2 */
  font-family: "PP Monument Extended", sans-serif;
font-size: 4rem; /* Adjust font size for smaller screens */
  font-weight: 800;
}
h3 {
  margin-top: 10px; /* 20px above the h1 */
  font-family: "PP Monument Extended", sans-serif;
font-size: 2rem; /* Adjust font size for smaller screens */
  font-weight: Bold;
  margin-bottom: 10px;
}
h4 {
  margin-top: 10px; /* 20px above the h1 */
  font-family: "PP Monument Extended", sans-serif;
font-size: 1.5rem; /* Adjust font size for smaller screens */
  font-weight: bold;
  margin-bottom: 10px;
}
p {
    font-size: 1rem; /* Adjust font size for tablets */
  }

     .hero-button {
    max-height: 50px;
  }

  .game-images {
    min-width: 80%; /* Adjust the width to 100% */
  }

  .bottom-image-container {
    max-height: 40%; /* Adjust height to half of the original */
    position: relative; /* Ensure proper positioning for child elements */
    display: flex; /* Keep display flex for centering */
    justify-content: center; /* Center the bottom image horizontally */
    align-items: flex-end; /* Align the image to the bottom */
    margin: 0 auto; /* Center the container itself */
  }
  
  .bottom-image {
    min-width: 80%; /* Ensure the image covers the container */
    height: auto; /* Maintain aspect ratio */
  }
}

@media (max-width: 600px) { /* Small mobile */
body {
   margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100vw; /* Ensure viewport width */
  height: 100vh; /* Ensure viewport height */
  overflow-x: hidden; /* Prevent horizontal scroll */
  background-color: black;
  background-image: url('./images/slimlongbackground.png');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
 body::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  background-image: url('./images/slimlongbackground.png');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  z-index: -1; /* Keep it behind other content */
}

  .hero {
  width: 100vw; /* Ensure hero section spans full viewport width */
  margin-left: 0;
  }
  
  .hero-content {
    align-items: center; /* Center content on smaller screens */
    max-width: 100%;
  }

  .chevron-icon {
    width: 30px; /* Smaller icon */
  }

h1 {
  margin-top: 1px; /* 20px above the h1 */
  font-family: "PP Monument Extended", sans-serif;
font-size: 4rem; /* Adjust font size for smaller screens */
  font-weight: 800;
  margin-bottom: 10px;
}
h2 {
  margin-bottom: 1px; /* 20px below the h2 */
  font-family: "PP Monument Extended", sans-serif;
font-size: 3rem; /* Adjust font size for smaller screens */
  font-weight: 800;
}

h3 {
  margin-top: 10px; /* 20px above the h1 */
  font-family: "PP Monument Extended", sans-serif;
font-size: 2rem; /* Adjust font size for smaller screens */
  font-weight: Bold;
  margin-bottom: 10px;
}
h4 {
  margin-top: 10px; /* 20px above the h1 */
  font-family: "PP Monument Extended", sans-serif;
font-size: 1.2rem; /* Adjust font size for smaller screens */
  font-weight: bold;
  margin-bottom: 10px;
}

p {
    font-size: 1rem; /* Further adjust for smaller devices */
  }
    .hero-button {
    max-height: 40px;
  }

  .game-images {
    max-width: 100%; /* Adjust width for tablet */
  }
   .bottom-image-container {
    max-height: 40%; /* Adjust height to half of the original */
    position: relative; /* Ensure proper positioning for child elements */
    display: flex; /* Keep display flex for centering */
    justify-content: center; /* Center the bottom image horizontally */
    align-items: flex-end; /* Align the image to the bottom */
    margin: 0 auto; /* Center the container itself */
  }
  
  .bottom-image {
    min-width: 80%; /* Ensure the image covers the container */
    height: auto; /* Maintain aspect ratio */
  }
}
