/* Global Styles */
@font-face {
  font-family: "PP Monument Extended";
  src: url('./fonts/PPMonumentExtended-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "PP Monument Extended";
  src: url('./fonts/PPMonumentExtended-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "PP Monument Extended";
  src: url('./fonts/PPMonumentExtended-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* Rewards Container */
.rewards-container {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
  background: url('./images/rewardsbackground.png') no-repeat top center;
  background-size: cover;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Rewards Content */
.rewards-content {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Centered Text */
.centered-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  line-height: 1.5;
}

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 1%;
}

.sznimage {
  max-width: 80%;
}

/* Banner Images */
.playimage {
  width: 100%; /* Make images take up 100% of the container's width */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure the image covers the container without stretching */
  display: block; /* Ensure image behaves as a block-level element */
}


.reward-image {
  width: 100%;
  max-width: 100vw;
  height: auto;
  object-fit: cover;
}

/* Prize Container */
.prizecontainer {
  width: 100%;
  min-height: 50vh; /* Adjust height as needed */
  background-color: rgba(255, 255, 255, 0.1); /* White with 10% opacity */
  display: flex;
  flex-direction: row; /* Side-by-side layout for larger screens */
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden; /* Prevent overflow */
  padding: 20px; /* Add padding for spacing */
  gap: 20px; /* Add spacing between items */
  box-sizing: border-box; /* Include padding in width calculations */
}

/* Prize Images */
.prize-image {
  width: 100%; /* Ensure image takes full width of its container */
  max-width: 20%; /* Limit image size on large screens */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure image doesn't distort */
}

/* Prize Left & Right */
.prize-left,
.prize-right {
  flex-shrink: 0; /* Prevent shrinking of images */
  width: auto; /* Allow width to adjust dynamically */
}

.prize-left {
  align-self: flex-start; /* Align on top */
}

.prize-right {
  align-self: flex-end; /* Align on bottom */
}

/* Centered Text */
.prize-text {
  flex: 1; /* Allow text to take up remaining space */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 10px; /* Ensure text doesn’t touch the edges */
}

.prize-text h2,
.prize-text h4,
.prize-text p {
  margin: 10px 0;
}

/* FAQ Container */
.faq-container {
  width: 100%;
  margin: 0;
  text-align: center;
  color: white;
  font-family: "PP Monument Extended", sans-serif;
  overflow-x: hidden;
  margin-top: 15vh; /* Added padding to top for spacing */
}

.accordion {
  width: 100%;
}

.accordion-item {
  margin-bottom: 10px; /* Create transparent gap between items */
  background-color: #333; /* Background color for each item */
  border-radius: 8px; /* Round corners for individual items */
  overflow: hidden; /* Ensure content doesn't spill out */
    background-image: url('./images/FAQtexture.png');
    background-size: 100% auto; /* Adjust width to container's width */
  background-repeat: repeat-y; /* Repeat horizontally */
}
.accordion-item:hover {
  background-color: #1F1F1F;
  background-image: url('./images/FAQtexture2.png');
 background-size: 100% auto; /* Adjust width to container's width */
  background-repeat: repeat-y; /* Repeat horizontally */
}

.accordion-header {
  display: flex; /* Use flex for alignment */
  justify-content: flex-start; /* Align items to the left */
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  font-weight: bold;
  gap: 10px; /* Add space between icon and text */
}

 .accordion-header p {
    font-size: 15px;
    text-align: left;
    font-family: "PP Monument Extended", sans-serif;
}

.accordion-header span {
  font-size: 20px;
  color: #FF4500;
}

.accordion-body {
  padding-top: 15px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 40%;
  line-height:150%;
  text-align: left;
}

 .accordion-body p {
    font-size: 14px;
    font-weight: 500;
}


/* Container Layout */
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap; /* Ensure text wraps under images on smaller screens */
  margin-top: 35vh;
}

.text-container-left2 {
  flex: 1;
  max-width: 50%;
  text-align: left;
  padding: 80px;
  box-sizing: border-box;
}

.iphone-image-container-left2 {
  flex: 1;
  max-width: 38%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px;
  box-sizing: border-box;
   margin-top: -40vh;
}

.image-container2 {
  max-width: 80%;
  position: relative;
}

.iphone-image {
  max-width: 100%;
  height: auto;
}

.iphone1 {
  position: relative;
  z-index: 1;
}

.iphone2 {
  position: absolute;
  top: 220px;
  left: 220px;
  z-index: 1;
}

/* Media Queries for Responsiveness */
/* Tablet (Max-width 1024px) */
@media (max-width: 1424px) {
  .reward-image {
    max-width: 90%;
  }
.prizecontainer {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: center;
    justify-content: center;
    gap: 15px; /* Reduce gap on smaller screens */
  }
  .prize-image {
    max-width: 25%; /* Shrink images slightly for screens between 1024px and 769px */
  }
  .container {
     margin-top: 20vh;
  }
  .iphone-image-container-left2 {
  flex: 1;
  max-width: 38%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px;
  box-sizing: border-box;
   margin-top: -20vh;
}
.faq-container {
  margin-top: 10vh; /* Added padding to top for spacing */
}
}

/* Tablet (Max-width 1024px) */
@media (max-width: 1024px) {
  .sznimage {
  max-width: 100%;
}
.rewards-container {
    background: url('./images/rewardsipadbackground.png') no-repeat top center;
    background-size: cover;
  }
  .reward-image {
    max-width: 90%;
  }
.prizecontainer {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: center;
    justify-content: center;
    gap: 15px; /* Reduce gap on smaller screens */
  }
  .prize-image {
    max-width: 20%; /* Shrink images slightly for screens between 1024px and 769px */
  }

  .container {
    flex-direction: column;
    align-items: center;
    margin-top: 15vh;
  }

.text-container-left2 {
  flex: 1;
  max-width: 80%;
  text-align: left;
  padding: 80px;
  margin-top: 20px;
  box-sizing: border-box;
}

.iphone-image-container-left2 {
  flex: 1;
  max-width: 48%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  box-sizing: border-box;
   margin-top: -10%;
}
.iphone1 {
    right: 100px;
  }

.iphone2 {
    top: 100px;
    left: 180px;
  }

.accordion-body {
  padding-top: 15px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 30%;
}
   .accordion-header p {
    font-size: 16px;
  }

  .accordion-body p {
    font-size: 13px;
  }
}

/* Mobile (Max-width 768px) */
@media (max-width: 768px) {

.rewards-container {
    background: url('./images/rewardsmobile.png') no-repeat top center;
    background-size: cover;
  }
.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 10%;
}
  .reward-image {
    max-width: 70%;
  }
.prizecontainer {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
  }
/*
  .prize-left {
    order: -1; 
  }

  .prize-right {
    order: 1; 
  }

  .prize-image {
    max-width: 60%; 
    margin: 10px 0;
  }

  .prize-text {
    margin: 10px 0;
  }*/
.accordion-body {
  padding-top: 15px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 20%;
}
  .accordion-header p {
    font-size: 14px;
  }

  .accordion-body p {
    font-size: 13px;
  }

  .container {
    flex-direction: column;
    align-items: center;
     margin-top: 10vh;
  }

  .text-container-left2 {
    flex: 1 1 100%;
    max-width: 90%;
    text-align: left;
    margin-top: 20px;
  }
  .iphone-image-container-left2 {
    flex: 1 1 100%;
    max-width: 70%;
     margin-top: -5vh;

  }
    .iphone1 {
  right: 80px;
}

  .iphone2 {
    top: 120px;
    left: 100px;
  }

  .faq-container {
  margin-top: 3vh; /* Added padding to top for spacing */
}

}

/* Small Screens (Max-width 480px) */
@media screen and (max-width: 480px) {
  .image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 15%;
}
.sznimage {
  max-width: 100%;
}
  .iphone-image {
    max-width: 150px;
  }

  .text-container-left2 {
    flex: 1 1 100%;
    max-width: 90%;
    text-align: left;
  }
 .iphone-image-container-left2 {
    flex: 1 1 100%;
    max-width: 80%;

  }

  .iphone2 {
    top: 100px;
    left: 80px;
  }
.accordion-body {
  padding-top: 15px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 20%;
}
  .accordion-header p {
    font-size: 12px;
  }

  .accordion-body p{
    font-size: 13px;
  }
}
